import { Box, Grid, Typography } from "@material-ui/core";
import Loader from "components/util/Loader";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getTeamScoreRank } from "store/user/userActions";
import { Division } from "store/themis_common_pb";
import { IError } from "../EventScheduleReport";
import { EventDateList, EventWithTeamScore, TeamScoreAndDivision, TeamSoreAndRank } from "store/themis_api_pb";
import { calculatePercentageOfTheDayTeamScore, calculateTeamScore, parseJSONFromString } from "lib/functions";
import { day_percentage_score_type } from "lib/constants";
export interface PublicScoreSheetProps {
  routeProps: RouteComponentProps<{
    eventId: string;
  }>;
}
export interface IOverlapDivision {
  startTs: string;
  endTs: string;
  teamScoreEventDivisionList: TeamScoreAndDivision.AsObject[];
  matchingDate: any;
}
export interface ITeamScorePercentageOfDay {
  division: {
    id: number;
    name: string;
    teamScoreRankList?: TeamSoreAndRank.AsObject[];
  };
}
export interface RestructureEvent {
  id: any;
  divName: any;
  dates: {
    date: string;
    lastDateDivisionInfoAndTeamInfo?: any;
    teamScoreRankList: TeamSoreAndRank.AsObject[];
  }[];
}
export interface IDayPercentageJSON {
  name: string;
  percentage?: string;
}
export interface ICalculatedTeamScore {
  [index: number]: any;
}
export interface IDayPercentage {
  [name: string]: number;
}
const MESSAGES = {
  loading: "Loading Score Sheet Report",
  notFound: "Record Not Found",
  noTeamsFound: "No Teams Found"
};
const LOADING_CONTENT_STYLE = {
  display: "flex",
  justifyContent: "center",
  marginTop: "300px",
  alignItems: "center"
};
const DEFAULT_IMAGE = "/themislogo.png";
const DEFAULT_FONT_WEIGHT = 600;
const BOLD_FONT_WEIGHT = "bold";
const EMPTY_STRING = "";
const LARGE_CONTENT_XS = 10;
const IMAGE_XS = 2;
const PublicScoreSheet: React.FC<PublicScoreSheetProps> = ({
  routeProps
}) => {
  const {
    eventId
  } = routeProps.match.params;
  const currentEventId = eventId !== undefined ? +eventId : null;

  // State Variables
  const [isLoading, setLoading] = useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<EventWithTeamScore.AsObject | undefined>();
  const [eventDivisionTeamScore, setEventDivisionTeamScore] = useState<EventDateList.AsObject[] | undefined>([]);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [calculatedTeamScores, setCalculatedTeamScores] = useState<any[]>();
  const [daysPercentage, setsDayPercentage] = useState<any>();
  const [selectedScoreType, setSelectedScoreType] = useState<string>("");
  const [selectedScoreDisplay, setSelectedScoreDisplay] = useState<string>("");

  // Fetch GetTeamScoreRank Data
  useEffect(() => {
    const fetchTeamScoreRank = async () => {
      setLoading(true);
      try {
        const teamScoreRankInfo = await getTeamScoreRank({
          eventId: currentEventId
        });
        const eventInfo = teamScoreRankInfo.event;
        const eventDateList = teamScoreRankInfo?.event?.eventDateListList;
        setEventDivisionTeamScore(eventDateList);
        setEventDetails(eventInfo);
      } catch (error) {
        const err = error as IError;
        const {
          message
        } = err;
        handelErrorMessage(message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    if (currentEventId !== undefined) {
      fetchTeamScoreRank();
    }
  }, [currentEventId]);
  useEffect(() => {
    var inputString = eventDetails?.daysPercentage;
    const extractType = eventDetails?.daysPercentage?.split(":")[0].trim();
    if (extractType !== undefined && inputString !== undefined) {
      const parsedObject = parseJSONFromString(inputString);
      const revampedDaysPercentageJSON = (parsedObject: IDayPercentage[]) => {
        return (parsedObject ?? []).map(value => {
          const extractObjectKey = Object.keys(value)[0];
          const extractObjectValue = Object.values(value)[0];
          return {
            name: extractObjectKey,
            percentage: extractObjectValue
          };
        });
      };
      const dayPercentage = revampedDaysPercentageJSON(parsedObject);
      setsDayPercentage(dayPercentage);
      const scoreDisplay = eventDetails?.scoreDisplay !== undefined ? eventDetails?.scoreDisplay : "";
      setSelectedScoreDisplay(scoreDisplay);
      setSelectedScoreType(extractType === day_percentage_score_type.percentage_of_the_day ? extractType : inputString);
    }
  }, [eventDivisionTeamScore, eventDetails]);
  useEffect(() => {
    if (selectedScoreDisplay !== undefined || daysPercentage! == undefined || selectedScoreType !== undefined || eventDivisionTeamScore !== undefined) {
      const teamScorePercentage = calculatePercentageOfTheDayTeamScore(eventDivisionTeamScore, daysPercentage, selectedScoreType);
      const teamScoreInfo = calculateTeamScore(teamScorePercentage, selectedScoreDisplay);
      if (teamScorePercentage !== undefined) {
        setCalculatedTeamScores(teamScorePercentage.teamScoreEventDivisionList);
      }
    }
  }, [eventDivisionTeamScore, daysPercentage, selectedScoreType, selectedScoreDisplay]);

  // Function to handel error MESSAGES
  const handelErrorMessage = (errorMessage: string) => {
    setErrorMessage(errorMessage);
  };

  // Loading Content
  const renderLoadingContent = () => {
    return <Grid style={{
      justifyContent: "center"
    }}>
        <Box style={{
        ...LOADING_CONTENT_STYLE
      }}>
          <Loader height="2%" width="2%" />
          <Typography style={{
          marginLeft: "20px",
          marginTop: "2px"
        }}>
            {MESSAGES.loading}.....
          </Typography>
        </Box>
      </Grid>;
  };

  //Function To Score Sheet Divider Content
  const renderDividerContent = () => {
    return <div style={{
      width: "100",
      border: "1px solid"
    }} />;
  };

  // Function To Handel Errors Message Content
  const renderErrorMessageContent = () => {
    return <Grid container justify="center" style={{
      height: "38rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }}>
        <Typography variant="h1" style={{
        textTransform: "capitalize"
      }}>
          {errorMessage ? errorMessage + "!" : EMPTY_STRING}
        </Typography>
      </Grid>;
  };

  // Function To Render Score Sheet Header Content
  const renderScoreSheetHeader = (division: Division.AsObject | undefined) => {
    const eventName = eventDetails?.name ?? EMPTY_STRING;
    const eventLogo = eventDetails?.logo ? eventDetails?.logo : DEFAULT_IMAGE;
    const brandName = eventDetails?.brand?.name;
    return <Grid container>
        <Grid item xs={LARGE_CONTENT_XS}>
          <Typography style={{
          fontSize: "22px",
          fontWeight: BOLD_FONT_WEIGHT,
          textTransform: "uppercase",
          marginBottom: "7px"
        }}>
            {brandName ?? EMPTY_STRING}
          </Typography>
          <Typography style={{
          fontSize: "22px",
          fontWeight: BOLD_FONT_WEIGHT,
          textTransform: "uppercase",
          marginBottom: "7px"
        }}>
            {eventName}
          </Typography>
          {renderDivisionInfo(division)}
        </Grid>
        <Grid item xs={IMAGE_XS}>
          <img src={eventLogo} alt="Event Logo" style={{
          height: "100px"
        }} />
        </Grid>
      </Grid>;
  };

  // Function To Render Score Sheet Division Content
  const renderDivisionInfo = (division: Division.AsObject | undefined) => {
    const divisionInfo = division !== undefined && division;
    const divisionName = divisionInfo ? divisionInfo?.name : EMPTY_STRING;
    return <Typography style={{
      fontSize: "18px",
      fontWeight: DEFAULT_FONT_WEIGHT,
      marginBottom: "20px"
    }}>
        Division:- {divisionName}
      </Typography>;
  };

  // Function To Render Not Found Content
  const renderNoTeamFoundContent = () => {
    return <Grid container justify="center">
        <Typography style={{
        fontWeight: DEFAULT_FONT_WEIGHT
      }}>
          {MESSAGES.noTeamsFound}!
        </Typography>
      </Grid>;
  };

  // Function To Render Score Sheet Division Team Content
  const renderDivisionTeamInfo = (teamScoreRankList: TeamSoreAndRank.AsObject[]) => {
    const header = [{
      id: 1,
      name: "Team Name"
    }, {
      id: 2,
      name: "Score"
    }, {
      id: 3,
      name: "Rank"
    }];
    const sortedOnScoreRankList = teamScoreRankList.sort((a, b) => +a.rank - +b.rank);
    return <div>
        <Grid container style={{
        marginBottom: "10px",
        marginTop: "14px"
      }}>
          {header.map(({
          id,
          name
        }) => <Grid key={`header_${id}`} item xs={id === 1 ? LARGE_CONTENT_XS : 1}>
              <Typography variant="h2" style={{
            marginLeft: id === 2 ? "8px" : ""
          }}>
                {name}
              </Typography>
            </Grid>)}
        </Grid>
        {!teamScoreRankList.length ? renderNoTeamFoundContent() : sortedOnScoreRankList.map(({
        programName,
        rank,
        score,
        teamName,
        teamId
      }, index: number) => {
        return <Grid container key={`team_info_${index}`} spacing={2}>
                    <Grid item xs={LARGE_CONTENT_XS}>
                      <Typography style={{
              textTransform: "capitalize",
              fontWeight: DEFAULT_FONT_WEIGHT,
              fontSize: "17px"
            }}>
                        {teamName ?? EMPTY_STRING}{" "}
                        {`${programName ? "-" : EMPTY_STRING} ${programName ?? EMPTY_STRING}`}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{
              fontWeight: DEFAULT_FONT_WEIGHT,
              fontSize: "17px"
              // marginLeft: "17px",
            }}>
                        {!isNaN(+score) ? score : EMPTY_STRING}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{
              fontWeight: DEFAULT_FONT_WEIGHT,
              fontSize: "17px"
            }}>
                        {rank ?? EMPTY_STRING}
                      </Typography>
                    </Grid>
                  </Grid>;
      })}
      </div>;
  };

  // Function To Render Score Sheet
  const renderScoreSheetContent = () => {
    return (calculatedTeamScores ?? []).map(({
      division,
      teamScoreRankList
    }, dateIndex) => <div key={`division_info_${dateIndex}`} style={{
      border: "2px solid",
      marginBottom: "30px",
      padding: "20px"
    }}>
          {renderScoreSheetHeader(division)}
          {renderDividerContent()}
          {renderDivisionTeamInfo(teamScoreRankList)}
        </div>);
  };
  const renderContent = () => {
    let content;
    if (isLoading) {
      content = renderLoadingContent();
    } else if (errorMessage) {
      content = renderErrorMessageContent();
    } else {
      content = renderScoreSheetContent();
    }
    return content;
  };

  // Conditional Rendering For Score Sheet
  return <div style={{
    padding: "20px"
  }}>{renderContent()}</div>;
};
export default PublicScoreSheet;