import { CircularProgress } from "@material-ui/core";
import React from "react";
interface ILoaderProps {
  width: string;
  height: string;
}
const Loader: React.FC<ILoaderProps> = ({
  height,
  width
}) => {
  return <CircularProgress color="primary" style={{
    width: width,
    height: height
  }} />;
};
export default Loader;