import { Action, createAction } from 'redux-actions'
import {
  BrandPermissions,
  emptyCategories,
  emptyPermissionCodes,
  emptyPermissions,
  EventPermissions,
  IUserState,
  JudgeEventPermission,
  JudgeEventPermissions,
  JudgeShift,
  LocationPermissions,
  ProducerPermissions,
  ProgramPermissions,
  TeamPermissions,
} from "./userReducer"
import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common from 'store/themis_common_pb'
import { Dispatch } from "react"
import {
  ACCESS_TOKEN,
  AnyDispatchType,
  AsFields,
  REFRESH_TOKEN,
  SET_CATEGORIES,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_PERMISSION_CODES,
  SET_PERMISSIONS,
  SET_TRY_LOGIN,
} from "lib/constants"
import { clearProgram } from "store/program/programActions"
import { clearProducer } from "store/producer/producerActions"
import {
  buildBrandUser,
  buildEventUser,
  buildLocationUser,
  buildProducerUser,
  buildProgramUser,
  buildTeamUser
} from "./userBuilders"
import { IAppState } from 'store/store'
import { clearLocation } from 'store/program/locationActions'
import { convertToNumber } from 'lib/functions'

interface LoginUserOptions {
  email: string
  password: string
}

export interface Categories {
  tiers: themis_common.Tier.AsObject[]
  divisions: themis_common.Division.AsObject[]
  allStarTypes: themis_common.AllStarType.AsObject[]
  levels: themis_common.DivisionLevel.AsObject[]
  scoringAuthorities: themis_common.ScoringAuthority.AsObject[]
}

export interface PermissionLists {
  program: themis_common.Permission.AsObject[]
  location: themis_common.Permission.AsObject[]
  team: themis_common.Permission.AsObject[]
  producer: themis_common.Permission.AsObject[]
  brand: themis_common.Permission.AsObject[]
  event: themis_common.Permission.AsObject[]
}

export interface BothSides {
  program: boolean
  producer: boolean
  judge: boolean
}

export interface IScoresByJudgeIdAndDivision {
  event_division_id: number | undefined;
  judge_id: number;
  producer_id: number | undefined;
  event_id: number;
  brand_id: number | undefined;
}

export interface IGetRubricByEventDivision {
  eventId: number | null;
  eventDivisionId: number | null;
}
export interface ITeamScoreRank {
  eventId: number | null;
}
export interface IScoreByTeamID {
  teamId: number | null | undefined;
  eventId: number | null | undefined;
}
export interface ITeamScoreAndRankByUserLocationRequest {
  UUID: number;
  locationId: number | undefined;
  teamId: number | undefined;
}

export interface IGetEventDivisionStraScoresBatch {
  eventId: number;
  eventDivisionId: number;
}

const setLogin = createAction<IUserState>(SET_LOGGED_IN)
const setTryLogin = createAction<boolean>(SET_TRY_LOGIN)
const setLoggedOut = createAction<void>(SET_LOGGED_OUT)
const setCategories = createAction<Categories>(SET_CATEGORIES)
const setPermissions = createAction<PermissionLists>(SET_PERMISSIONS)
const setPermissionCodes = createAction<themis_common.PermissionCodes.AsObject>(SET_PERMISSION_CODES)

export function tryLogin(dispatch: Dispatch<Action<boolean>>, state: boolean): void {
  dispatch(setTryLogin(state))
}

export const refreshUser = async (dispatch?: AnyDispatchType): Promise<void> => {
  const refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN)
  if (refreshToken?.length) {
    if (dispatch) {
      tryLogin(dispatch, true)
    }
    try {
      const refreshRequest = new themis_api.RefreshRequest()
      refreshRequest.setRefreshToken(refreshToken)
      const response: themis_api.UserAuthResponse.AsObject = (await client.refresh(refreshRequest, {})).toObject()
      await finishLogin(response, dispatch)
      if (dispatch) {
        return tryLogin(dispatch, false)
      }
    } catch (e) {
      if (dispatch) {
        return tryLogin(dispatch, false)
      }
      throw e
    }
  }
}

export const loginUser = (loginDetails: LoginUserOptions) => {
  return async (dispatch: AnyDispatchType): Promise<void> => {
    const userAuthRequest = new themis_api.UserAuthRequest()
    userAuthRequest.setEmail(loginDetails.email)
    userAuthRequest.setPassword(loginDetails.password)
    const response: themis_api.UserAuthResponse.AsObject = (await client.userAuth(userAuthRequest, {})).toObject()
    return finishLogin(response, dispatch)
  }
}

interface JudgePermShift {
  Divs: number
  ShiftID: number
  StartTime: string
  FloorName: string
  PanelName: string
  Day: string
  JudgeId: number
  JudgeTypes: any,
  EventJudgeShiftID: number,
}

interface JudgePerms {
  EventID: number
  EventLogo: string
  EventName: string
  EventStartDate: string
  Shifts: { [i: string]: JudgePermShift }
  JudgeID: number
}

const fixJudgePerms = (judgePerms: { [x: string]: JudgePerms}): JudgeEventPermissions => {
  const outPerms: JudgeEventPermissions = {}
  for (const permId of Object.keys(judgePerms)) {
    const perm = judgePerms[permId]
    const fixedPerm: JudgeEventPermission = {
      eventStartDate: perm.EventStartDate,
      id: perm.EventID,
      logo: perm.EventLogo,
      name: perm.EventName,
      shifts: {},
      judgeId: perm.JudgeID
    }
    for (const shiftId of Object.keys(perm.Shifts)) {
      const shift = perm.Shifts[shiftId]
      const fixedShift: JudgeShift = {
        divs: shift.Divs,
        floorName: shift.FloorName,
        panelName: shift.PanelName,
        shiftId: shift.ShiftID,
        startTime: shift.StartTime,
        day: shift.Day,
        judgeId: shift.JudgeId,
        judgeTypes: shift.JudgeTypes,
        eventJudgeShiftId: shift.EventJudgeShiftID,
      }
      fixedPerm.shifts[shift.ShiftID] = fixedShift
    }

    outPerms[perm.EventID] = fixedPerm
  }

  return outPerms
}

export const finishLogin = async (response: themis_api.UserAuthResponse.AsObject, dispatch?: AnyDispatchType, ) => {
  if (response && response.jwt && response.jwt.length > 0) {
    const jwt = response.jwt
    const refreshToken = response.refreshToken
    localStorage.setItem(ACCESS_TOKEN, jwt)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)

    if (dispatch) {
      const userInfo: AsFields = parseJwt(jwt)
      // Get a list of producer > brands for later sync processing
      if (!userInfo.ProducerPermissions) userInfo.ProducerPermissions = []

      // Get a list of program > locations for later sync processing
      if (!userInfo.ProgramPermissions) userInfo.ProgramPermissions = []

      // Get a list of event > panels for later sync processing
      if (!userInfo.JudgePermissions) userInfo.JudgePermissions = []

      const parsedProducers: ProducerPermissions = {}
      for (const producerId in userInfo.ProducerPermissions) {
        const producer = userInfo.ProducerPermissions[producerId]
        const parsedBrands: BrandPermissions = {}
        for (const brandId in producer.Brands) {
          const brand = producer.Brands[brandId]
          const parsedEvents: EventPermissions = {}
          for (const eventId in brand.Events) {
            parsedEvents[eventId] = brand.Events[eventId]
          }
          parsedBrands[brandId] = {
            id: Number(brandId),
            name: brand.Name,
            logo: brand.Logo,
            perms: brand.Perms,
            events: parsedEvents
          }
        }
        parsedProducers[producerId] = {
          id: Number(producerId),
          name: producer.Name,
          logo: producer.Logo,
          perms: producer.Perms,
          brands: parsedBrands
        }
      }

      const parsedPrograms: ProgramPermissions = {}

      for (const programId in userInfo.ProgramPermissions) {
        const program = userInfo.ProgramPermissions[programId]
        const parsedLocations: LocationPermissions = {}
        for (const locationId in program.Locations) {
          const location = program.Locations[locationId]
          const parsedTeams: TeamPermissions = {}
          for (const teamId in location.Teams) {
            parsedTeams[teamId] = location.Teams[teamId]
          }
          parsedLocations[locationId] = {
            id: Number(locationId),
            name: location.Name,
            perms: location.Perms,
            teams: parsedTeams
          }
        }
        parsedPrograms[programId] = {
          id: Number(programId),
          name: program.Name,
          logo: program.Logo,
          perms: program.Perms,
          locations: parsedLocations
        }
      }
      const loginInfo: IUserState = {
        user: userInfo,
        loggedIn: true,
        tryLogin: false,
        registerProgram: false,
        permissions: {
          producers: parsedProducers,
          programs: parsedPrograms,
          judges: fixJudgePerms(userInfo.JudgePermissions),
        },
        program: !!Object.keys(userInfo.ProgramPermissions).length,
        producer: !!Object.keys(userInfo.ProducerPermissions).length,
        judge: !!userInfo.JudgePermissions,
        categories: emptyCategories,
        permissionLists: emptyPermissions,
        permissionCodes: emptyPermissionCodes, // TODO?: permission codes from backend?
      }
      dispatch(setLogin(loginInfo))
    }
  } else {
    throw new Error('Login Failed, please try again')
  }
}

export const checkEmail = async (email: string): Promise<boolean> => {

  const checkEmailRequest = new themis_api.CheckEmailRequest()
  checkEmailRequest.setEmail(email)
  const res = await client.checkEmail(checkEmailRequest, {})
  const response = res.toObject()
  return response.okay
}

export const registerUser = (registrationDetails: AsFields, _producer?: boolean, _program?: boolean) => {
  return async (dispatch: AnyDispatchType): Promise<void> => {

    const userRegistrationRequest = new themis_api.RegisterRequest()
    userRegistrationRequest.setName(`${registrationDetails.firstName + ' ' + registrationDetails.lastName}`)
    userRegistrationRequest.setFirstName(registrationDetails.firstName)
    userRegistrationRequest.setLastName(registrationDetails.lastName)
    userRegistrationRequest.setBirthDate(registrationDetails.birthDate)
    userRegistrationRequest.setEmail(registrationDetails.email)
    userRegistrationRequest.setPassword(registrationDetails.password)
    userRegistrationRequest.setIpAddress(registrationDetails.ipAddress)
    userRegistrationRequest.setAgreedToTerms(registrationDetails.agreedToTerms)

    const res = await client.register(userRegistrationRequest, {})
    const response = res.toObject()
    if (response.userId > 0) {
      const login = loginUser({ email: registrationDetails.email, password: registrationDetails.password })
      await login(dispatch)
    } else {
      throw new Error("Error registering user")
    }
  }
}

export const updatePassword = async (password: string): Promise<boolean> => {
  const updatePasswordRequest = new themis_api.UpdatePasswordRequest()
  updatePasswordRequest.setPassword(password)
  const res = await client.updatePassword(updatePasswordRequest, {})
  const response = res.toObject()
  return response.okay
}

export const logoutUser = () => {
  return async (dispatch: AnyDispatchType): Promise<void> => {
    const currentJwt = parseJwt(localStorage?.getItem(ACCESS_TOKEN) ?? "")
    if (!currentJwt.impersonated) {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      localStorage.removeItem("CURRENT_EVENT")
      localStorage.removeItem("CURRENT_TEAM")
      localStorage.removeItem('producerConnectLink')
      await dispatch(setLoggedOut())
      await clearProducer(dispatch)
      await clearProgram(dispatch)
    } else {
      const oldAuth = new themis_api.UserAuthResponse()
      oldAuth.setJwt(localStorage?.getItem("OLD_ACCESS_TOKEN") ?? "")
      oldAuth.setRefreshToken(localStorage?.getItem("OLD_REFRESH_TOKEN") ?? "")
      await clearProducer(dispatch)
      await clearProgram(dispatch)
      await clearLocation(dispatch)
      finishLogin(oldAuth.toObject(), dispatch)
      localStorage.removeItem('OLD_ACCESS_TOKEN')
      localStorage.removeItem('OLD_REFRESH_TOKEN')
      localStorage.removeItem('OLD_USERNAME')
      localStorage.removeItem("latestProgramId")
      localStorage.removeItem("latestProducerId")
      localStorage.removeItem("latestLocationId")
      localStorage.removeItem("latestBrandId")
      window.location.replace("/")
    }
  }
}

export const parseJwt = (token: string): AsFields => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

export const getCategories = async (dispatch: AnyDispatchType): Promise<void> => {
  const emptyMessage = new themis_api.EmptyMessage()
  const response = (await client.getCategories(emptyMessage, {})).toObject()
  const tiers = response['tiersList']
  const divisions = response['divisionsList']
  const allStarTypes = response['allStarTypesList']
  const scoringAuthorities = response['scoringAuthoritiesList']
  const levels = response['levelsList']
  dispatch(setCategories({ tiers, divisions, allStarTypes, scoringAuthorities, levels }))
}

export const getPermissions = async (dispatch: AnyDispatchType): Promise<void> => {
  const emptyMessage = new themis_api.EmptyMessage()
  const response = (await client.getPermissions(emptyMessage, {})).toObject()
  const program = response['programPermissionsList']
  const location = response['locationPermissionsList']
  const team = response['teamPermissionsList']
  const producer = response['producerPermissionsList']
  const brand = response['brandPermissionsList']
  const event = response['eventPermissionsList']

  dispatch(setPermissions({ program, location, team, producer, brand, event }))
}

export const getPermissionCodes = async (dispatch: AnyDispatchType): Promise<void> => {
  const emptyMessage = new themis_api.EmptyMessage()
  const response: themis_common.PermissionCodes.AsObject = (await client.getPermissionCodes(emptyMessage, {})).toObject()

  dispatch(setPermissionCodes(response))
}

export const updatePermissions = async (dispatch: AnyDispatchType, object: string, objectId: number, userId: number, permissions: themis_common.Permission.AsObject[], superObjectId?: number, superDuperObjectId?: number): Promise<void> => {
  switch (object) {
    case "program":
      const programUser = buildProgramUser(objectId, userId, undefined, undefined, permissions)
      await client.editProgramUser(programUser, {})
      break
    case "location":
      if (!superObjectId) return
      const locationUser = buildLocationUser(superObjectId, objectId, userId, undefined, undefined, permissions)
      await client.editLocationUser(locationUser, {})
      break
    case "team":
      if (!superObjectId || !superDuperObjectId) return
      const teamUser = buildTeamUser(superDuperObjectId, superObjectId, objectId, userId, undefined, undefined, permissions)
      await client.editTeamUser(teamUser, {})
      break
    case "producer":
      const producerUser = buildProducerUser(objectId, userId, undefined, undefined, permissions)
      await client.editProducerUser(producerUser, {})
      break
    case "brand":
      if (!superObjectId) return
      const brandUser = buildBrandUser(superObjectId, objectId, userId, undefined, undefined, permissions)
      await client.editBrandUser(brandUser, {})
      break
    case "event":
      if (!superObjectId || !superDuperObjectId) return
      const eventUser = buildEventUser(superDuperObjectId, superObjectId, objectId, userId, undefined, undefined, permissions)
      await client.editEventUser(eventUser, {})
      break
  }
}

export const addObjectUser = async (object: string, objectId: number, name: string, email: string, superObjectId?: number, superDuperObjectId?: number): Promise<void> => {
  switch (object) {
    case "program":
      const programUser = buildProgramUser(objectId, undefined, name, email)
      await client.addProgramUser(programUser, {})
      break
    case "location":
      if (!superObjectId) return
      const locationUser = buildLocationUser(superObjectId, objectId, undefined, name, email)
      await client.addLocationUser(locationUser, {})
      break
    case "team":
      if (!superObjectId || !superDuperObjectId) return
      const teamUser = buildTeamUser(superDuperObjectId, superObjectId, objectId, undefined, name, email)
      await client.addTeamUser(teamUser, {})
      break
    case "producer":
      const producerUser = buildProducerUser(objectId, undefined, name, email)
      await client.addProducerUser(producerUser, {})
      break
    case "brand":
      if (!superObjectId) return
      const brandUser = buildBrandUser(superObjectId, objectId, undefined, name, email)
      await client.addBrandUser(brandUser, {})
      break
    case "event":
      if (!superObjectId || !superDuperObjectId) return
      const eventUser = buildEventUser(superDuperObjectId, superObjectId, objectId, undefined, name, email)
      await client.addEventUser(eventUser, {})
      break
  }
  return
}

export const editUser = async (userId: number, name: string, email: string): Promise<void> => {
  const user = new themis_common.User()
  user.setId(userId)
  user.setName(name)
  user.setEmail(email)
  await client.editUser(user, {})
}

export const deleteObjectUser = async (objectType: string, objectId: number, userId: number, superObjectId?: number, superDuperObjectId?: number): Promise<void> => {
  switch (objectType) {
    case "program":
      const programUser = buildProgramUser(objectId, userId)
      await client.deleteProgramUser(programUser, {})
      break
    case "location":
      if (!superObjectId) return
      const locationUser = buildLocationUser(superObjectId, objectId, userId)
      await client.deleteLocationUser(locationUser, {})
      break
    case "team":
      if (!superObjectId || !superDuperObjectId) return
      const teamUser = buildTeamUser(superDuperObjectId, superObjectId, objectId, userId)
      await client.deleteTeamUser(teamUser, {})
      break
    case "producer":
      const producerUser = buildProducerUser(objectId, userId)
      await client.deleteProducerUser(producerUser, {})
      break
    case "brand":
      if (!superObjectId) return
      const brandUser = buildBrandUser(superObjectId, objectId, userId)
      await client.deleteBrandUser(brandUser, {})
      break
    case "event":
      if (!superObjectId || !superDuperObjectId) return
      const eventUser = buildEventUser(superDuperObjectId, superObjectId, objectId, userId)
      await client.deleteEventUser(eventUser, {})
      break
  }
  return
}

export const getUserById = async (userId: number): Promise<themis_common.User.AsObject> => {
  const user = new themis_common.User()
  user.setId(userId)
  return (await client.getUserById(user, {})).toObject()
}

export const addBugReport = async (bugReportObject: AsFields, reduxState: IAppState, path: string, userId: number | null, coordsObject?: GeolocationCoordinates): Promise<void> => {
  const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN)
  const darkState: string | null = localStorage.getItem('darkState')
  const programOrProducerOrJudge: string | null = localStorage.getItem('programOrProducer')
  const productVersion = "2021-05-14_0.0.1"

  const addBugReportRequest = buildBugReport(accessToken, darkState, programOrProducerOrJudge, bugReportObject, reduxState, path, userId, productVersion, coordsObject)
  await client.addBugReport(addBugReportRequest, {})

}

export const buildBugReport = (accessToken: string | null, darkState: string | null, programOrProducer: string | null, bugReportObject: AsFields, reduxState: IAppState, path: string, userId: number | null, productVersion: string, coordsObject?: GeolocationCoordinates): themis_api.BugReport => {
  const bugReport = new themis_api.BugReport()

  if (userId) bugReport.setUserId(userId)
  bugReport.setProductVersion(productVersion)
  bugReport.setReduxState(JSON.stringify(reduxState))
  bugReport.setGeolocation(JSON.stringify({ ...coordsObject, latitude: coordsObject?.latitude, longitude: coordsObject?.longitude, accuracy: coordsObject?.accuracy }))
  bugReport.setPath(path)
  if (accessToken) bugReport.setAccessToken(accessToken)
  if (darkState) bugReport.setDarkState(darkState)
  if (programOrProducer) bugReport.setProgramOrProducer(programOrProducer)
  bugReport.setType(bugReportObject.type)
  bugReport.setDescription(bugReportObject.description)

  return bugReport
}

export const  getUserGeolocation = async (handleGeoLocation: (position: GeolocationPosition) => void): Promise<void> => {
  if ("geolocation" in navigator) {
    try {
      navigator.geolocation.getCurrentPosition(position => {
        handleGeoLocation(position)
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export const getStripePK = async (): Promise<string> => {
  const request = new themis_api.EmptyMessage()
  const res: themis_api.StripePKResponse = await client.getStripePK(request, {})
  return (res.toObject()).stripePK
}

export const shareAuth = async (email: string, password: string): Promise<string> => {

  const shareAuthRequest = new themis_api.UserAuthRequest()
  shareAuthRequest.setEmail(email)
  shareAuthRequest.setPassword(password)

  const res = await client.shareAuth(shareAuthRequest, {})

  const shareAuthResponse = res.toObject()
  return shareAuthResponse.code

}

export const userAuthShared = async (accessCode: string, dispatch: AnyDispatchType): Promise<themis_api.UserAuthResponse.AsObject> => {
  const authSharedRequest = new themis_api.AuthSharedRequest()
  authSharedRequest.setCode(accessCode)

  localStorage.removeItem("latestProgramId")
  localStorage.removeItem("latestProducerId")
  localStorage.removeItem("latestLocationId")
  localStorage.removeItem("latestBrandId")
  const res = await client.userAuthShared(authSharedRequest, {})

  const oldAccessToken = localStorage.getItem('ACCESS_TOKEN')
  const oldRefreshToken = localStorage.getItem('REFRESH_TOKEN')

  if (oldAccessToken !== null && oldRefreshToken !== null) {
    localStorage.setItem('OLD_ACCESS_TOKEN', oldAccessToken)
    localStorage.setItem('OLD_REFRESH_TOKEN', oldRefreshToken)
  }



  const authSharedResponse: themis_api.UserAuthResponse.AsObject = res.toObject()

  finishLogin(authSharedResponse, dispatch)

  return authSharedResponse

}

export const getDocumentSignatureDetails = async (documentCode: string): Promise<themis_common.DocumentSignatureGuardian.AsObject> => {
  const documentRequest = new themis_api.DocumentSignatureRequest()
  documentRequest.setSignCode(documentCode)
  return (await client.getSignatureInfo(documentRequest, {})).toObject()
}

export const getDocumentSigntures = async (eventDocumentIds: number[], athleteId: number, guardianId?: number | undefined): Promise<themis_api.SignatureQueryResponse.AsObject> => {
  const signatureQueryRequest = new themis_api.SignatureQueryRequest()
  signatureQueryRequest.setAthleteId(athleteId)
  if (guardianId) signatureQueryRequest.setGuardianId(guardianId)
  signatureQueryRequest.setEventDocumentIdsList(eventDocumentIds)
  return (await client.getSignatures(signatureQueryRequest, {})).toObject()
}

export const checkGuardianAthleteValid = async (guardianId: number | undefined, athleteId: number): Promise<boolean> => {
  if (!guardianId || !athleteId) return false

  const checkValid = new themis_api.GuardianAthleteRequest()
  const athlete = new themis_common.Athlete()
  athlete.setId(athleteId)
  checkValid.setAthlete(athlete)
  const guardian = new themis_common.Guardian()
  guardian.setId(guardianId)
  checkValid.setGuardian(guardian)
  const ret: themis_common.GuardianAhtleteValid.AsObject = (await client.checkGuardianAthleteValid(checkValid, {})).toObject()
  return ret.validguardianofathlete
}

export const setGuardianAthleteValid = async (guardianId: number | undefined, athleteId: number, connectString: string): Promise<boolean> => {
  if (!guardianId || !athleteId) return false

  const checkValid = new themis_api.GuardianAthleteRequest()
  const athlete = new themis_common.Athlete()
  athlete.setId(athleteId)
  checkValid.setAthlete(athlete)
  const guardian = new themis_common.Guardian()
  if (guardianId) guardian.setId(guardianId)
  checkValid.setGuardian(guardian)
  checkValid.setConnectString(connectString)
  const ret: themis_common.GuardianAhtleteValid.AsObject = (await client.setGuardianAthleteValid(checkValid, {})).toObject()
  return ret.validguardianofathlete
}

export const saveSignature = async (theSignCode: string, signature: string): Promise<boolean> => {
  const signatureRequest = new themis_api.SignatureRequest()
  signatureRequest.setSignature(signature)
  signatureRequest.setSignCode(theSignCode)

  await client.setGuardianSignature(signatureRequest, {})
  return true
}

export const saveElectSignatureAgree = async (theSignCode: string, answer: boolean): Promise<boolean> => {
  const electSigAgreement = new themis_api.ElectSigAgreement()
  electSigAgreement.setAgree(answer)
  electSigAgreement.setSignCode(theSignCode)

  const electSigResponse: themis_api.ElectSigAgreementResponse.AsObject = (await client.saveGuardianElectSignatureAgreement(electSigAgreement, {})).toObject()
  return electSigResponse.agreed
}

export const signTheDocument = async (theSignature: themis_common.DocumentSignature.AsObject, theSignCode: string, signature: string): Promise<void> => {
  const docSig = new themis_api.DocumentSignatureRequest()
  docSig.setSignCode(theSignCode)
  docSig.setSignature(signature)
  docSig.setEventDocumentId(theSignature.eventDocumentId)
  docSig.setDocumentSignatureId(theSignature.id)
  await client.signDocument(docSig, {})
}

export const requestReset = async (fields: AsFields): Promise<void> => {
  const request = new themis_common.User()
  request.setEmail(fields.email)
  await client.requestPasswordReset(request, {})
}

export const resetPassword = async (fields: AsFields): Promise<void> => {
  const request = new themis_api.CodeRequest()
  request.setCode(fields.code)
  request.setPassword(fields.password)
  await client.updateUserEmailWithCode(request, {})
}

export const testPasswordResetCode = async (code: string): Promise<boolean> => {
  const request = new themis_api.CodeRequest()
  request.setCode(code)
  const res: themis_api.StatusResponse.AsObject = (await client.testPasswordResetCode(request, {})).toObject()
  return res.status
}

export const getEventDivisionsByShift = async (shiftId: number, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventDivisionShift.AsObject[]> => {
  const shift = new themis_common.EventFloorDateJudgingPanelShift()
  shift.setId(shiftId)
  const eventDivisions = []
  const eventDivision = new themis_common.EventDivision()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  const brand = new themis_common.Brand()
  brand.setProducerId(producerId)
  event.setBrand(brand)
  eventDivision.setEvent(event)
  eventDivisions.push(eventDivision)
  shift.setEventDivisionsList(eventDivisions)
  const res: themis_api.EventDivisionShifts.AsObject = (await client.getEventDivisionShiftsByShift(shift, {})).toObject()
  return res.eventDivisionShiftsList
}

export const saveRubricAreaScores = async (scores: themis_common.EventDivisionShiftTeamRubricAreaScore.AsObject[], edstId: number, edsId: number, producerId: number, brandId: number, eventId: number): Promise<themis_api.TeamScores.AsObject> => {
  const area = new themis_common.EventDivisionShiftTeamRubricArea()
  const edst = new themis_common.EventDivisionShiftTeam()
  const event = new themis_common.Event()
  const eventDivisionShift = new themis_common.EventDivisionShift()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  edst.setEvent(event)
  eventDivisionShift.setId(edsId)
  edst.setEventDivisionShift(eventDivisionShift)
  edst.setId(edstId)
  area.setEventDivisionShiftTeam(edst)
  const scoreList = scores.filter(score => (!(!score.judge?.id || !score.rubricAreaItemId || !score.rubricAreaId)) ).map(score => {
    const edstras = new themis_common.EventDivisionShiftTeamRubricAreaScore()
    if (!score.judge?.id || !score.rubricAreaItemId || !score.rubricAreaId){
      return edstras
    } // This will never happen because the filter above but typescript...
    const judge = new themis_common.Judge()
    judge.setId(score.judge?.id || 1)
    edstras.setJudge(judge)
    edstras.setRubricAreaItemId(score.rubricAreaItemId)
    edstras.setRubricAreaId(score.rubricAreaId)
    edstras.setScore(score.score)
    edstras.setComment(score.comment)
    return edstras
  })

  area.setEventDivisionShiftTeamRubricAreaScoresList(scoreList)

  return (await client.addEventDivisionShiftTeamRAScores(area, {})).toObject()
}

export const scoreEventDivisionShift = async (edsId: number, judgeId: number): Promise<themis_api.TeamScores.AsObject> => {
  const payload = new themis_api.EventFloorDateJudgingPanelShiftDivisionRequest()
  payload.setEventFloorDateJudgingPanelShiftDivisionId(edsId)
  payload.setJudgeId(judgeId)

  return (await client.scoreEventDivisionShift(payload, {})).toObject()

}

export const getEventDivisionShiftTeam = async (edstId: number, producerId: number, brandId: number, eventId: number, judgeId: number): Promise<themis_common.EventDivisionShiftTeam.AsObject | undefined> => {
  const edst = new themis_common.EventDivisionShiftTeam()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  edst.setEvent(event)
  edst.setId(edstId)

  const teamReturn: themis_api.EventDivisionShiftTeamReturn.AsObject = (await client.getEventDivisionShiftTeam(edst, {})).toObject()
  const shiftTeam = teamReturn.eventDivisionShiftTeam
  if (shiftTeam) {
    const areaList = shiftTeam?.eventDivisionShiftTeamRubricAreasList.map((area) => {
      return {
        ...area,
        eventDivisionShiftTeamRubricAreaScoresList: area.eventDivisionShiftTeamRubricAreaScoresList.filter(score => {
          return score.judge?.id === judgeId
        }).map(score => {
          return { ...score, score: (Math.round(convertToNumber(score.score) * 100) / 100).toString() }
        })
      }
    })
    shiftTeam.eventDivisionShiftTeamRubricAreasList = areaList || []

    return shiftTeam
  }
  return undefined
}

export const getShiftReportData = async (efdjpsId: number): Promise<themis_api.ShiftScores.AsObject> => {
  const efdjps = new themis_common.EventFloorDateJudgingPanelShift()
  efdjps.setId(efdjpsId)
  const reportData: themis_api.ShiftScores.AsObject = (await client.shiftReport(efdjps, {})).toObject()
  return reportData
}

export const getTeamReportData = async (eventDivisionShiftId: number): Promise<themis_api.TeamReport.AsObject> => {
  const eventDivisionShift = new themis_common.EventDivisionShift()
  eventDivisionShift.setId(eventDivisionShiftId)
  const reportData: themis_api.TeamReport.AsObject = (await client.getTeamReport(eventDivisionShift, {})).toObject()
  return reportData

}

export const getRubric = async (rubricId: number): Promise<themis_common.Rubric.AsObject> => {
  const rubric = new themis_common.Rubric()
  rubric.setId(rubricId)
  const rubricData: themis_common.Rubric.AsObject = (await client.getRubricById(rubric, {})).toObject()
  return rubricData
}

export const closeEventShiftDivision = async (divisionId: number, judgeId: number, panelShiftId: number, status: boolean): Promise<void> => {
  const payload = new themis_api.EventFloorDateJudgingPanelShiftDivisionRequest()
  payload.setStatus(status)
  payload.setJudgeId(judgeId)
  payload.setEventFloorDateJudgingPanelShiftId(panelShiftId)
  payload.setEventFloorDateJudgingPanelShiftDivisionId(divisionId)
  await client.approveEventFloorDateJudgingPanelShiftDivision(payload, {})
}

export const getScoresByJudgeIdAndDivision = async (
  payload: IScoresByJudgeIdAndDivision
): Promise<themis_api.ScoresByJudgeIdAndDivisionResponse.AsObject> => {
  const { event_division_id, judge_id, producer_id, event_id, brand_id } =
    payload;
  const scoresByJudgeIdAndDivisionRequest =
    new themis_api.GetScoresByJudgeIdAndDivisionRequest();
  scoresByJudgeIdAndDivisionRequest.setEventDivisionId(event_division_id as number);
  scoresByJudgeIdAndDivisionRequest.setJudgeId(judge_id as number);
  scoresByJudgeIdAndDivisionRequest.setProducerId(producer_id as number);
  scoresByJudgeIdAndDivisionRequest.setEventId(event_id);
  scoresByJudgeIdAndDivisionRequest.setBrandId(brand_id as number);
  const response = (await client.getScoresByJudgeIdAndDivision(
    scoresByJudgeIdAndDivisionRequest,
    {}
  )).toObject();
  return response;
};

export const getRubricsByProducerId = async (producerId: number, divisionId: number): Promise<themis_api.ProducerDivisionRubricsList.AsObject> => {
  const types = new themis_api.ProducerDivisionRubricsRequest()
  types.setProducerId(producerId);
  types.setDivisionId(divisionId);

  return (await client.getProducerDivisionRubrics(types, {})).toObject()
}

export const submitRubricScore = async (rubricData: any, edstId: number, edsId: number, producerId: number, brandId: number, eventId: number): Promise<themis_api.TeamScores.AsObject> => {
  const area = new themis_common.EventDivisionShiftTeamRubricArea()
  const eventDivisionShift = new themis_common.EventDivisionShift()
  eventDivisionShift.setId(edsId)
  eventDivisionShift.setTeamsList([])

  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId) 
  event.setRegistrationCodesList([])
  event.setEventDatesList([])
  event.setEventFloorsList([])
  event.setTeamsList([])
  event.setSeasonsList([])
  event.setEventDivisionList([])
  event.setEventDocumentsList([])
  
  const edst = new themis_common.EventDivisionShiftTeam()
  edst.setEvent(event)
  edst.setEventDivisionShift(eventDivisionShift)
  edst.setId(edstId) 
  edst.setCheerReplayVideosList([])
  edst.setEventDivisionShiftTeamRubricAreasList([])
  edst.setEventDivisionShiftTeamDeductionsList([])

  const scoreList = rubricData.filter((score: any) => (!(!score.judge?.id || !score.rubricAreaItemId || !score.rubricAreaId)) ).map((score: any) => {
    const edstras = new themis_common.EventDivisionShiftTeamRubricAreaScore()
    if (!score.judge?.id || !score.rubricAreaItemId || !score.rubricAreaId){
      return edstras
    } // This will never happen because the filter above but typescript...
    const judge = new themis_common.Judge()
    judge.setId(score.judge?.id)
    judge.setJudgeProducersList([])

    edstras.setJudge(judge)
    edstras.setScore(score.score)
    edstras.setRubricAreaId(score.rubricAreaId)
    edstras.setRubricAreaItemId(score.rubricAreaItemId)
    edstras.setComment(score.comment ? score.comment : "")
    edstras.setDynamicFormJsonValues(score.dynamicFormJsonValues)
    return edstras
  })

  area.setEventDivisionShiftTeamRubricAreaScoresList(scoreList)
  area.setEventDivisionShiftTeam(edst)

  return (await client.addEventDivisionShiftTeamRAScores(area, {})).toObject()  
}

export const getEventDivisionStraScores = async (producerId: number, brandId: number, eventId: number, judgeId: number, eventDivisionShiftTeamRaId: number): Promise<themis_api.GetEventDivisionStraScoresResponse.AsObject> => {
  const score = new themis_api.GetEventDivisionStraScoresRequest()
  score.setProducerId(producerId)
  score.setBrandId(brandId)
  score.setEventId(eventId)
  score.setJudgeId(judgeId)
  score.setEventDivisionShiftTeamRaId(eventDivisionShiftTeamRaId)

  return (await client.getEventDivisionStraScores(score, {})).toObject()
}

export const getEventDivisionStraScoresBatch = async (payload: IGetEventDivisionStraScoresBatch[]): Promise<themis_api.GetEventDivisionStraScoresBatchResponse.AsObject> => {
  const request = new themis_api.GetEventDivisionStraScoresBatchRequest()
  request.setRequestList(payload)
  return (await client.getEventDivisionStraScoresBatch(request, {})).toObject()
}

export const getScoreAndJudgeTypes = async (producerId: number, brandId: number, eventId: number, shiftId: number, eventDivisionId: number, eventTeamId: number): Promise<themis_api.ScoreAndJudgeTypeResponse.AsObject> => {
  const score = new themis_api.GetScoreAndJudgeTypeRequest()
  score.setProducerId(producerId)
  score.setBrandId(brandId)
  score.setEventId(eventId)
  score.setShiftId(shiftId)
  score.setEventDivisionId(eventDivisionId)
  score.setEventTeamId(eventTeamId)

  return (await client.getScoreAndJudgeTypes(score, {})).toObject()
}

export const getRubricRAStraData = async (edstId: number) => {
  const request = new themis_api.GetEventDivisionStraRequest()
  request.setEventDivisionShiftTeamId(edstId);
  
   const data =  (await client.getEventDivisionStra(request, {})).toObject()
   return data.eventDivisionStrasList;
  }

  // Fetch all divisions based on event ID
export const getTeamScoreRank = async (
  payload: ITeamScoreRank
): Promise<themis_api.GetTeamScoreRankResponse.AsObject> => {
  const { eventId } = payload;
  const getTeamScoreRankRequest = new themis_api.PublicDivisionRequest();
  if (typeof eventId === "number") {
    getTeamScoreRankRequest.setEventId(eventId);
  }
  return (
    await client.getTeamScoreRank(getTeamScoreRankRequest, {})
  ).toObject();
};
// Fetch all divisions and teams based on user ID and location ID.
export const getTeamScoreAndRankByUserLocation = async (
  payload: ITeamScoreAndRankByUserLocationRequest
): Promise<themis_api.TeamScoreAndRankByUserLocationResponse.AsObject> => {
  const { UUID, locationId, teamId } = payload;
  const request = new themis_api.TeamScoreAndRankByUserLocationRequest();
  if (typeof locationId === "number" && typeof teamId === "number") {
    request.setUserId(UUID);
    request.setTeamId(teamId);
    request.setLocationId(locationId);
  }
  return (
    await client.getTeamScoreAndRankByUserLocation(request, {})
  ).toObject();
};
// Fetch all team information based on team ID
export const getScoreByTeamID = async (
  payload: IScoreByTeamID
): Promise<themis_api.RubricResponse.AsObject> => {
  const { eventId, teamId } = payload;
  const scoreByTeamIDRequest = new themis_api.GetScoreByTeamIDRequest();
  if (typeof eventId === "number" && typeof teamId === "number") {
    scoreByTeamIDRequest.setEventId(eventId);
    scoreByTeamIDRequest.setTeamId(teamId);
  }
  return (await client.getScoreByTeamID(scoreByTeamIDRequest, {})).toObject();
};