import React, { useEffect, useState } from "react";
import { Box, Button, Grid, InputAdornment, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Loader from "components/util/Loader";
// import { TeamSoreAndRank } from "store/themis_api_pb";
import { IError } from "../EventScheduleReport";
import { AltRoute, Clear as ClearIcon } from "@material-ui/icons";
import { getScoreByTeamID, getTeamScoreAndRankByUserLocation } from "store/user/userActions";
import { formateDate } from "lib/functions";
import themis_common, { Event } from "store/themis_common_pb";
import { ConstantData, DivisionInfo, EventWithTeamScore, RubricAreaJsonAndScore, RubricInfo, TeamScoreAndRankByUserLocationResponse } from "store/themis_api_pb";
import { TeamScoreAndDivision, TeamSoreAndRank } from "store/themis_api_pb";
interface ScoreSheetProps {
  routeProps?: RouteComponentProps<{
    eventId: string;
  } & {
    teamId: string;
  }>;
  showFilters: boolean;
  enableRouting?: boolean;
  teamId?: number;
  currentLocation?: themis_common.Location.AsObject;
  program?: themis_common.Program.AsObject;
}
export interface IFilters {
  eventName: string;
  divisionName: string;
  teamName: string;
}
const INITIAL_FILTERS: IFilters = {
  divisionName: "",
  eventName: "",
  teamName: ""
};
interface IData {
  event: EventWithTeamScore.AsObject | undefined;
}
interface IDataWithTeamScore extends IData {
  event: IData["event"] & {
    teamScoreEventDivisionList: TeamScoreAndDivision.AsObject[];
  };
}
const MESSAGES = {
  notFound: "Record Not Found!",
  loading: "Loading Score Sheet Report",
  filters: "No data match your current filter",
  notFoundRubric: "Score sheet data was not found"
};
const FILTER_PLACEHOLDER = {
  eventName: "Filter By Event Name",
  divisionName: "Filter By Division Name",
  teamName: "Filter By Team Name"
};
const CONTAINER_STYLE = {
  border: "2px solid",
  width: "100%",
  height: "100%",
  minHeight: "155px",
  boxSizing: "border-box",
  overflow: "hidden"
};
const RUBRIC_AREA_CONTAINER_STYLE = {
  border: "2px solid",
  width: "100%",
  minHeight: "160px",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  overflow: "hidden"
};
const RUBRIC_STYLE = {
  width: "100%",
  height: "50px",
  marginTop: "12px",
  background: "black",
  color: "white",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};
const FONT_WEIGHT_STYLE = {
  bold: "bold",
  600: 600
};
const LOADING_OR_ERROR_CONTENT_STYLE = {
  display: "flex",
  justifyContent: "center",
  marginTop: "300px",
  alignItems: "center"
};
const LARGE_CONTENT_XS = 10;
const IMAGE_XS = 2;
const HEADER_TEXT_CONTENT_FONT_SIZE = "20px";
const DEFAULT_IMAGE = "/themislogo.png";
const PRIMARY_COLOR = "#b30808";
const EVENT_NAME_FONT_SIZE = "23px";
const EMPTY_STRING = "";
const LOADING_MILL_SECOND = 1000;
const calculateTeamTemplate4Rating = (rubricAreaInfo: RubricAreaJsonAndScore.AsObject[]): string => {
  try {
    // Single object to track all category counts across all rubric areas
    const combinedCategoryCounts: Record<string, number> = {};
    let highestCount = 0;
    let dominantCategory = '';

    // Process all scores
    rubricAreaInfo.forEach(item => {
      if (!item.scoreDynamicJson) return;
      const data = JSON.parse(item.scoreDynamicJson);
      const sectionKey = Object.keys(data)[0];
      const sectionData = data[sectionKey];
      if (sectionData?.ratings?.categoryDescriptionCount) {
        // Add each category count to our combined totals
        Object.entries(sectionData.ratings.categoryDescriptionCount).forEach(([category, count]) => {
          combinedCategoryCounts[category] = (combinedCategoryCounts[category] || 0) + count;

          // Update dominant category if this one has a higher total
          if (combinedCategoryCounts[category] > highestCount) {
            highestCount = combinedCategoryCounts[category];
            dominantCategory = category;
          }
        });
      }
    });
    return dominantCategory;
  } catch (e) {
    console.error('Failed to calculate team template-4 rating:', e);
    return '';
  }
};
const renderTemplate4Table = (scoreDynamicJson: string, comment?: string) => {
  try {
    const data = JSON.parse(scoreDynamicJson);
    const sectionKey = Object.keys(data)[0];
    const sectionData = data[sectionKey];
    if (!sectionData?.ratings?.radioValues) return null;
    const formatSectionTitle = (key: string) => {
      return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).trim();
    };
    const rowValues = sectionData.ratings.radioValues.reduce((acc: Record<string, string>, row: any) => {
      const [label] = row.value.split('_&_');
      const column = row.value.split('_&_')[1];
      acc[label] = column;
      return acc;
    }, {});
    const columnTotals = {
      Low: 0,
      Average: 0,
      High: 0
    };
    Object.values(rowValues).forEach(column => {
      if (column in columnTotals) {
        columnTotals[column as keyof typeof columnTotals]++;
      }
    });
    return <Box sx={{
      marginY: 2
    }}>
        <Typography variant="h6" sx={{
        marginBottom: 1,
        fontWeight: 'bold'
      }}>
          {formatSectionTitle(sectionKey)}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criteria</TableCell>
                <TableCell align="center">Low</TableCell>
                <TableCell align="center">Average</TableCell>
                <TableCell align="center">High</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectionData.ratings.radioValues.map((row: any) => {
              const [label, column] = row.value.split('_&_');
              return <TableRow key={label}>
                    <TableCell>{label}</TableCell>
                    <TableCell align="center">{column === 'Low' ? '✓' : ''}</TableCell>
                    <TableCell align="center">{column === 'Average' ? '✓' : ''}</TableCell>
                    <TableCell align="center">{column === 'High' ? '✓' : ''}</TableCell>
                  </TableRow>;
            })}
              <TableRow sx={{
              backgroundColor: '#f5f5f5'
            }}>
                <TableCell sx={{
                fontWeight: 'bold'
              }}>Total Checkmarks</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.Low}</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.Average}</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.High}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {comment && <Typography variant="body2" sx={{
        marginTop: 1
      }}>
            Comment: {comment}
          </Typography>}
      </Box>;
  } catch (e) {
    console.error('Failed to parse template-4 data:', e);
    return null;
  }
};
const renderRubricComments = (rubricData: RubricAreaJsonAndScore.AsObject[] | undefined) => {
  if (!rubricData) return null;
  return rubricData.map(({
    dynamicJson: stringifyDynamicJson,
    score,
    comment,
    scoreDynamicJson
  }, index) => {
    try {
      const dynamicJsonObj = JSON.parse(stringifyDynamicJson);
      const isTemplate4 = dynamicJsonObj[0]?.data?.items?.[0]?.templateName === 'template-4';
      if (isTemplate4 && scoreDynamicJson) {
        return renderTemplate4Table(scoreDynamicJson, comment);
      }
      return Object.keys(dynamicJsonObj).map((objectKey, keyIndex) => {
        const flatArray = dynamicJsonObj[objectKey]?.data?.items.map((item: any) => item.data.rows).flat()?.[0]?.[0];
        const staticLabelTitle = flatArray?.find((row: any) => row.type === "staticLabel")?.title;
        return staticLabelTitle && staticLabelTitle.length > 0 ? <Box key={`dynamic_json_${index}_${keyIndex}`} style={{
          border: "1px solid white",
          padding: "10px",
          marginBottom: "10px",
          fontWeight: FONT_WEIGHT_STYLE[600],
          marginLeft: "10px",
          width: "96%",
          wordBreak: "break-word",
          '@media print': {
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }
        }}>
            <Typography>
              {staticLabelTitle}
            </Typography>
            <Typography>
              {comment ? "Comment: " + comment : EMPTY_STRING}
              <br />
              Points {score}
            </Typography>
          </Box> : null;
      });
    } catch (e) {
      console.error('Failed to parse rubric data:', e);
      return null;
    }
  });
};
const ScoreSheet: React.FC<ScoreSheetProps> = ({
  routeProps,
  showFilters,
  currentLocation,
  program,
  enableRouting,
  teamId
}) => {
  // Extract routing params ids
  const eventDivisionTeam = routeProps?.match.params.teamId ?? undefined;
  const eventId = routeProps?.match.params.eventId ?? undefined;

  //
  const programUserId = program?.id ? program?.id : undefined;
  const currentLocationId = currentLocation?.id ? currentLocation?.id : undefined;

  // State Variables
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [filterParams, setFilterParams] = useState<IFilters>(INITIAL_FILTERS);
  const [eventParameters, setEventParameters] = useState<{
    eventName: string;
  }>();
  const [teamScoreInfo, setTeamScoreInfo] = useState<ConstantData.AsObject | undefined>();
  const [filteredEvents, setFilteredEvents] = useState<IDataWithTeamScore[]>();
  const [filteredData, setFilteredData] = useState<IDataWithTeamScore[] | undefined>();
  const [userLocationTeamScoreInfo, setUserLocationTeamScoreInfo] = useState<IDataWithTeamScore[] | undefined>();
  const [userLocationTeamScore, setUserLocationTeamScore] = useState<IDataWithTeamScore[]>();
  // Fetch UserLocationTeamScoreInfo Details
  useEffect(() => {
    const fetchUserLocationTeamScoreInfo = async () => {
      setLoading(true);
      try {
        const userId = programUserId;
        const locationId = currentLocationId;
        const eventTeamId = teamId ? teamId : 0;
        const response = await getTeamScoreAndRankByUserLocation({
          locationId,
          teamId: eventTeamId,
          UUID: userId as number
        });
        const hasUserLocationTeamScoreInfo = response.responseList.length;
        const message = !hasUserLocationTeamScoreInfo ? MESSAGES.notFound : EMPTY_STRING;
        handelErrorMessage(message);
        const transformEventData = (response: TeamScoreAndRankByUserLocationResponse.AsObject) => {
          const flattenedTeamScoreInfo = response.responseList.flatMap(({
            event
          }) => event?.eventDateListList.flatMap(({
            teamScoreEventDivisionList
          }) => teamScoreEventDivisionList));
          const modifiedEvents = response.responseList.map(({
            event
          }) => ({
            event: {
              ...event,
              teamScoreEventDivisionList: flattenedTeamScoreInfo
            }
          }));
          return modifiedEvents;
        };
        // Example usage:
        const transformedData = transformEventData(response);
        const responseList = transformedData as unknown as IDataWithTeamScore[] | undefined;
        setUserLocationTeamScoreInfo(responseList);
        setUserLocationTeamScore(responseList);
        setFilteredData(responseList);
        setFilteredEvents(responseList);
      } catch (error) {
        const err = error as IError;
        handelErrorMessage(err.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, LOADING_MILL_SECOND);
      }
    };
    if (!eventId) {
      fetchUserLocationTeamScoreInfo();
    }
  }, [eventId, teamId, currentLocation, program]);

  // Fetch TeamScoreInfo Details
  useEffect(() => {
    const areRequiredIds = eventId !== undefined && eventDivisionTeam !== undefined;
    if (areRequiredIds) {
      const fetchTeamScoreInfo = async () => {
        setLoading(true);
        try {
          const response = await getScoreByTeamID({
            eventId: Number(eventId),
            teamId: Number(eventDivisionTeam)
          });
          console.log(response);
          const {
            constantData
          } = response;
          setTeamScoreInfo(constantData);
          console.log("CONSTANT DATA STRING", JSON.stringify(constantData));
        } catch (error) {
          const err = error as IError;
          const {
            message
          } = err;
          handelErrorMessage(message);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, LOADING_MILL_SECOND);
        }
      };
      fetchTeamScoreInfo();
    }
  }, [eventId, eventDivisionTeam, currentLocation, program]);
  useEffect(() => {
    handleFilters();
  }, [filterParams]);
  useEffect(() => {
    handelEventFilter();
  }, [eventParameters]);

  // Filter's for teamScoreAndRankByUserLocation score sheet
  const filterEventsByEventName = (events: IDataWithTeamScore[], eventName: string) => eventName && eventName.length ? events.filter(eventInfo => eventInfo.event && eventInfo.event.name && eventInfo.event.name.toLowerCase().includes(eventName.toLowerCase())) : events;

  // Filter by division name
  const filterEventsByDivisionName = (events: IDataWithTeamScore[], divisionName: string) => divisionName && divisionName.length ? events.filter(eventInfo => eventInfo.event && eventInfo.event.teamScoreEventDivisionList && eventInfo.event.teamScoreEventDivisionList.some(({
    division,
    teamScoreRankList
  }) => {
    if (teamScoreRankList.length) {
      return division?.name.toLowerCase().includes(divisionName.toLowerCase());
    }
  })) : events;

  // Filter by team name
  const filterTeamNames = (teamScoreRankList: TeamSoreAndRank.AsObject[], teamName: string) => teamScoreRankList.filter(({
    teamName: rankTeamName
  }) => rankTeamName.toLowerCase().includes(teamName.toLowerCase()));

  // Filter by division team name
  const filterEventsByTeamName = (events: IDataWithTeamScore[], teamName: string) => teamName && teamName.length ? events.map(eventInfo => {
    if (!eventInfo.event || !eventInfo.event.teamScoreEventDivisionList) return eventInfo;
    const updatedEvent = {
      ...eventInfo.event
    };
    updatedEvent.teamScoreEventDivisionList = eventInfo.event.teamScoreEventDivisionList.map(({
      teamScoreRankList,
      ...division
    }) => ({
      ...division,
      teamScoreRankList: filterTeamNames(teamScoreRankList, teamName)
    }));
    return {
      ...eventInfo,
      event: updatedEvent
    };
  }) : events;
  const appliedFilters = (events: IDataWithTeamScore[] | undefined, filters = INITIAL_FILTERS) => {
    const {
      eventName,
      divisionName,
      teamName
    } = filters;
    if (!events) return []; // If events are not provided, return an empty array

    let filteredData = filterEventsByEventName(events, eventName);
    filteredData = filterEventsByDivisionName(filteredData, divisionName);
    filteredData = filterEventsByTeamName(filteredData, teamName);
    return filteredData;
  };

  // Filters based on the filter's parameters
  const handleFilters = () => {
    const filteredData = appliedFilters(userLocationTeamScoreInfo, filterParams);
    setFilteredData(filteredData);
  };

  // Filter for only event name
  const filterEventsByEvent = (events: IDataWithTeamScore[] | undefined, eventName: string | undefined) => {
    if (!eventName) {
      return events;
    }
    return events?.filter(({
      event
    }) => event?.name.toLowerCase().includes(eventName?.toLowerCase()));
  };
  const handelEventFilter = () => {
    const filteredEvents = filterEventsByEvent(userLocationTeamScore ?? [], eventParameters?.eventName);
    const events = eventParameters?.eventName ? filteredEvents : userLocationTeamScore;
    setFilteredEvents(events);
  };

  // Function to highlighted filtered text
  const highlightedText = (text: string, highlight: string, fontSize: string) => {
    if (!text || !highlight) {
      return text;
    } else {
      const lowerText = text.toLowerCase();
      const lowerHighlight = highlight.toLowerCase();
      if (lowerText.includes(lowerHighlight)) {
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return parts.map((part, index) => {
          const matchedText = part.toLowerCase() === highlight.toLowerCase();
          return matchedText ? <Typography key={index} variant={"inherit"} style={{
            color: PRIMARY_COLOR,
            fontWeight: "bold",
            fontSize
          }}>
              {part}
            </Typography> : part;
        });
      }
    }
    return text;
  };

  // Function to handel error message
  const handelErrorMessage = (error: string) => {
    return setErrorMessage(error);
  };

  // Function to handel render filter's content
  const renderFilters = () => {
    const handelFilters = (eventName: string, divisionName: string, teamName: string) => {
      const hasFilters = eventName.length || divisionName.length || teamName.length;
      if (hasFilters) {
        setFilterParams(() => ({
          ...filterParams,
          eventName,
          divisionName,
          teamName
        }));
      }
    };
    const clearFilters = (eventName: string, divisionName: string, teamName: string) => {
      setFilterParams(() => ({
        ...filterParams,
        eventName,
        divisionName,
        teamName
      }));
    };
    return showFilters ? <Grid container style={{
      marginBottom: "20px",
      marginLeft: "13px"
    }}>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.eventName} value={filterParams.eventName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            teamName,
            divisionName
          } = filterParams;
          handelFilters(searchValue, divisionName, teamName);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.eventName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                teamName,
                divisionName
              } = filterParams;
              clearFilters(removeSearch, divisionName, teamName);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.divisionName} value={filterParams.divisionName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            eventName,
            teamName
          } = filterParams;
          handelFilters(eventName, searchValue, teamName);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.divisionName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                eventName,
                teamName
              } = filterParams;
              clearFilters(eventName, removeSearch, teamName);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.teamName} value={filterParams.teamName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            divisionName,
            eventName
          } = filterParams;
          handelFilters(eventName, divisionName, searchValue);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.teamName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                divisionName,
                eventName
              } = filterParams;
              clearFilters(eventName, divisionName, removeSearch);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
      </Grid> : null;
  };

  // Function to render event's filter
  const renderEventFilter = () => {
    return teamId ? <Grid container style={{
      marginBottom: "20px",
      marginLeft: "13px"
    }}>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.eventName} value={eventParameters?.eventName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          setEventParameters({
            ...eventParameters,
            eventName: searchValue
          });
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {eventParameters?.eventName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              setEventParameters({
                ...eventParameters,
                eventName: removeSearch
              });
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
      </Grid> : null;
  };

  // Function to handel redirect routing
  const handelRouting = (eventId: number, teamId: number) => {
    if (eventId !== undefined && teamId !== undefined) {
      const redirectURL = `score_sheet/event/${eventId}/team/${teamId}`;
      window.open(redirectURL);
    }
  };

  // Function to render header content
  const renderHeader = (event: Event.AsObject | EventWithTeamScore.AsObject, divisionInfo: TeamScoreAndDivision.AsObject | DivisionInfo.AsObject | undefined, teamInfo: TeamSoreAndRank.AsObject | any, isFirstRubric: boolean = true) => {
    // Only render the header if it's the first rubric
    if (!isFirstRubric) return null;
    const divisionTeamInfo = teamInfo as TeamSoreAndRank.AsObject;
    const isVisibleScoreSheetButton = !enableRouting || teamId;
    const eventLogo = event?.logo ? event?.logo : DEFAULT_IMAGE;
    const hasRubric = teamScoreInfo?.divisioninfo?.teamsinfoList.some(({
      rubricinfoList
    }) => rubricinfoList.length);
    const teamName = divisionTeamInfo.teamName ? divisionTeamInfo.teamName : teamInfo.name ? teamInfo.name : " ";
    const programName = divisionTeamInfo.programName ?? EMPTY_STRING;
    const divisionName = divisionInfo?.division?.name ?? EMPTY_STRING;
    const param = teamId ? eventParameters?.eventName : filterParams.eventName;
    return <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={LARGE_CONTENT_XS}>
          <Typography style={{
          fontWeight: "bold",
          fontSize: EVENT_NAME_FONT_SIZE,
          marginLeft: "10px",
          marginTop: "10px"
        }}>
            {highlightedText(event.name ?? EMPTY_STRING, param ?? EMPTY_STRING, EVENT_NAME_FONT_SIZE)}{" "}
            {formateDate(event?.firstDay ?? EMPTY_STRING)}
          </Typography>
          <Grid style={{
          marginLeft: "10px",
          marginTop: "10px"
        }}>
            <Typography style={{
            fontWeight: FONT_WEIGHT_STYLE[600],
            fontSize: HEADER_TEXT_CONTENT_FONT_SIZE
          }}>
              Team: {}
              {highlightedText(programName + " " + teamName ?? EMPTY_STRING, filterParams.teamName, HEADER_TEXT_CONTENT_FONT_SIZE)}{" "}
            </Typography>
            <Typography style={{
            marginTop: "10px",
            fontWeight: FONT_WEIGHT_STYLE[600],
            fontSize: HEADER_TEXT_CONTENT_FONT_SIZE
          }}>
              Division:{}
              {highlightedText(divisionName ?? EMPTY_STRING, filterParams.divisionName, HEADER_TEXT_CONTENT_FONT_SIZE)}{" "}
            </Typography>
            {isVisibleScoreSheetButton ? <Button color="primary" variant="contained" size="small" style={{
            marginTop: "10px",
            marginBottom: "10px"
          }} onClick={() => {
            const eventId = event.id ?? 0;
            const teamId = divisionTeamInfo.teamId ?? 0;
            handelRouting(eventId, teamId);
          }}>
                View Score Sheet
              </Button> : null}
          </Grid>
        </Grid>
        <Grid item xs={IMAGE_XS} style={{
        display: "flex",
        alignItems: "center"
      }}>
          <img src={eventLogo} alt="Athlete Image" style={{
          height: "100px"
        }} />
        </Grid>
      </Grid>;
  };

  // Function to render rubricArea content
  const renderRubricArea = (rubricAreaName?: string, rubricAreaInfo?: RubricAreaJsonAndScore.AsObject[], rubricAreaIndex?: number) => {
    const stringifyRubricAreaInfo = JSON.stringify(rubricAreaInfo);
    const parsedRubricAreaInfo: RubricAreaJsonAndScore.AsObject[] | undefined = JSON.parse(stringifyRubricAreaInfo);
    const isAllTemplate4 = parsedRubricAreaInfo?.every(item => {
      try {
        const dynamicJson = JSON.parse(item.dynamicJson);
        return dynamicJson[0]?.data?.items?.[0]?.templateName === 'template-4';
      } catch {
        return false;
      }
    });
    let rating = '';
    if (isAllTemplate4 && parsedRubricAreaInfo) {
      rating = calculateTeamTemplate4Rating(parsedRubricAreaInfo);
    }
    const totalRubricAreaScore = (parsedRubricAreaInfo ?? []).reduce((totalScore, rubricData) => totalScore + +rubricData.score, 0);
    const renderRubricContent = (rubricData: RubricAreaJsonAndScore.AsObject[] | undefined) => {
      if (!rubricData) return null;

      // Group by judge type for non-template-4 items
      const groupedByJudgeType = new Map<string, {
        categories: Map<string, {
          score: number;
          title: string;
          comments: string[];
        }>;
        totalScore: number;
      }>();

      // Separate template-4 items
      const template4Items: JSX.Element[] = [];
      rubricData.forEach(({
        dynamicJson: stringifyDynamicJson,
        score,
        comment,
        scoreDynamicJson
      }) => {
        try {
          const dynamicJsonObj = JSON.parse(stringifyDynamicJson);
          const isTemplate4 = dynamicJsonObj[0]?.data?.items?.[0]?.templateName === 'template-4';
          if (isTemplate4 && scoreDynamicJson) {
            const template4Element = renderTemplate4Table(scoreDynamicJson, comment);
            if (template4Element) {
              template4Items.push(template4Element);
            }
            return;
          }

          // Handle non-template-4 items
          Object.keys(dynamicJsonObj).forEach(objectKey => {
            const items = dynamicJsonObj[objectKey]?.data?.items || [];
            const judgeType = items[0]?.judgeType || 'Unknown Judge Type';
            const flatArray = items.map((item: any) => item.data.rows).flat()?.[0]?.[0];
            const staticLabelTitle = flatArray?.find((row: any) => row.type === "staticLabel")?.title;
            if (staticLabelTitle) {
              // Initialize judge type if not exists
              if (!groupedByJudgeType.has(judgeType)) {
                groupedByJudgeType.set(judgeType, {
                  categories: new Map(),
                  totalScore: 0
                });
              }
              const judgeGroup = groupedByJudgeType.get(judgeType)!;
              const categoryName = staticLabelTitle.split('\n')[0].trim();

              // Initialize or update category
              if (!judgeGroup.categories.has(categoryName)) {
                judgeGroup.categories.set(categoryName, {
                  score: 0,
                  title: staticLabelTitle,
                  comments: []
                });
              }
              const category = judgeGroup.categories.get(categoryName)!;

              // Update scores and add comment if exists
              category.score += Number(score);
              if (comment) {
                category.comments.push(comment);
              }
              judgeGroup.totalScore += Number(score);
            }
          });
        } catch (e) {
          console.error('Failed to parse rubric data:', e);
        }
      });

      // Render both template-4 and grouped non-template-4 items
      return <>
          {template4Items}
          {Array.from(groupedByJudgeType.entries()).map(([judgeType, group], index) => <Box key={`judge_type_${index}`}>
              <Typography variant="h6" style={{
            marginTop: '20px',
            marginBottom: '10px',
            marginLeft: '10px',
            fontWeight: 'bold'
          }}>
                {judgeType} (Total Score: {group.totalScore.toFixed(3)})
              </Typography>
              {Array.from(group.categories.entries()).map(([categoryName, category], catIndex) => <Box key={`category_${catIndex}`} style={{
            border: "1px solid white",
            padding: "10px",
            marginBottom: "10px",
            fontWeight: FONT_WEIGHT_STYLE[600],
            marginLeft: "20px",
            width: "95%",
            wordBreak: "break-word",
            '@media print': {
              breakInside: 'avoid',
              pageBreakInside: 'avoid'
            }
          }}>
                  <Typography>
                    {category.title}
                  </Typography>
                  {category.comments.length > 0 && <Typography style={{
              marginTop: '8px'
            }}>
                      Comments:<br />
                      {category.comments.map((comment, i) => <span key={i}>• {comment}<br /></span>)}
                    </Typography>}
                  <Typography style={{
              marginTop: '8px'
            }}>
                    Points: {category.score.toFixed(1)}
                  </Typography>
                </Box>)}
            </Box>)}
        </>;
    };
    return <Grid container style={RUBRIC_AREA_CONTAINER_STYLE}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid container className="rubric-heading">
              <h2 style={{
              margin: "0 auto",
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
                {rubricAreaName}
              </h2>
            </Grid>
            {isAllTemplate4 ? <Typography variant="h1" style={{
            marginLeft: "10px",
            textTransform: "capitalize",
            marginBottom: "8px",
            border: rating ? "2px solid" : "",
            marginTop: "14px",
            padding: "5px",
            marginRight: "5px"
          }}>
                {rating && <table style={{
              width: "100%",
              textAlign: "center",
              borderCollapse: "collapse"
            }}>
                    <tbody>
                      <tr>
                        <td style={{
                    border: "1px solid white"
                  }}>Rating</td>
                      </tr>
                      <tr>
                        <td style={{
                    border: "1px solid white"
                  }}>{rating}</td>
                      </tr>
                    </tbody>
                  </table>}
              </Typography> : <Grid item xs={2}>
                <Typography variant="h1" style={{
              marginLeft: "10px",
              textTransform: "capitalize",
              marginBottom: "8px",
              border: totalRubricAreaScore ? "2px solid" : "",
              marginTop: "14px",
              padding: "5px",
              marginRight: "5px"
            }}>
                  {totalRubricAreaScore ? <table style={{
                width: "100%",
                textAlign: "center",
                borderCollapse: "collapse"
              }}>
                      <tbody>
                        <tr>
                          <td style={{
                      border: "1px solid white"
                    }}>
                            {isAllTemplate4 ? 'Rating' : 'Total'}
                          </td>
                        </tr>
                        <tr>
                          <td style={{
                      border: "1px solid white"
                    }}>
                            {totalRubricAreaScore.toFixed(3)}
                          </td>
                        </tr>
                      </tbody>
                    </table> : null}
                </Typography>
              </Grid>}
          </Grid>
          {renderRubricContent(parsedRubricAreaInfo)}
        </Grid>
      </Grid>;
  };

  // Function to render rubric content
  const renderRubrics = (rubrics: RubricInfo.AsObject[]) => {
    const notFoundRubric = () => {
      return <Grid container justify="center">
          <Typography variant="h1" style={{
          marginTop: "40px"
        }}>
            {MESSAGES.notFoundRubric}.
          </Typography>
        </Grid>;
    };
    console.log("RUBRICS", rubrics);
    return !rubrics.length ? notFoundRubric() : rubrics.map(({
      name,
      rubricareainfoList
    }, index: number) => <React.Fragment key={`rubric_${index}`}>
            {/* <Grid container style={RUBRIC_STYLE}>
              <h2
                style={{
                  margin: "0 auto",
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {name}
              </h2>
             </Grid> */}
            {rubricareainfoList.filter(({
        rubricareajsonandscoreList
      }) => rubricareajsonandscoreList.length > 0).map(({
        name: rubricAreaName,
        rubricareajsonandscoreList
      }, rubricAreaIndex: number) => renderRubricArea(rubricAreaName, rubricareajsonandscoreList, rubricAreaIndex))}
          </React.Fragment>);
  };
  const notFoundData = () => {
    return <Grid container justify="center">
        <Typography variant="h2" style={{
        marginTop: "3rem"
      }}>
          {MESSAGES.filters}.
        </Typography>
      </Grid>;
  };

  // // Function to render score sheet in score sheet tab
  const renderScoreSheet = () => {
    const isVisibleRubric = enableRouting;
    const notFoundTeams = !filteredData?.length || filteredData?.every(({
      event
    }) => event?.teamScoreEventDivisionList.some(({
      teamScoreRankList
    }) => teamScoreRankList.some(({
      teamName,
      programName
    }) => teamName.length || programName.length)));
    return !notFoundTeams ? notFoundData() : (filteredData ?? []).map(({
      event
    }, index) => event?.teamScoreEventDivisionList.map(divisionInfo => divisionInfo?.teamScoreRankList.map((teamInfo, teamIndex) => {
      return <Grid container key={`score_sheet_${teamIndex}`} style={{
        padding: "14px"
      }}>
                  {index !== 0 ? <div style={{
          border: "2px solid",
          width: "100%",
          marginBottom: "29px"
        }} /> : null}
                  {renderHeader(event, divisionInfo, teamInfo)}
                  {isVisibleRubric ? renderRubricArea() : null}
                </Grid>;
    })));
  };

  // // Function to render score sheet in team score sheet tab
  const renderScoreSheetByTeam = () => {
    const notFound = !filteredEvents?.length;
    return notFound ? notFoundData() : filteredEvents?.map(({
      event
    }, index) => event?.teamScoreEventDivisionList.map(divisionInfo => divisionInfo.teamScoreRankList.map((teamInfo, teamIndex) => {
      return <Grid container key={`score_sheet_${teamIndex}`} style={{
        padding: "14px"
      }}>
                  {index !== 0 ? <div style={{
          border: "2px solid",
          width: "100%",
          marginBottom: "29px"
        }} /> : null}
                  {renderHeader(event, divisionInfo, teamInfo)}
                </Grid>;
    })));
  };

  // // Function to render score sheet routing part
  const renderScoreSheetByTeamId = () => {
    const isRubricVisible = enableRouting && eventDivisionTeam;
    return teamScoreInfo?.divisioninfo?.teamsinfoList.map((teamInfo, index) => {
      return <Grid container key={`score_sheet_${index}`} style={{
        padding: "14px"
      }}>
          {renderHeader(teamScoreInfo.eventinfo as Event.AsObject, teamScoreInfo.divisioninfo, teamInfo, index === 0)}
          {isRubricVisible ? renderRubrics(teamInfo.rubricinfoList) : null}
        </Grid>;
    });
  };

  // Function to render loading content
  const renderLoader = () => {
    return <Grid style={{
      justifyContent: "center"
    }}>
        <Box style={{
        ...LOADING_OR_ERROR_CONTENT_STYLE
      }}>
          <Loader height="2%" width="2%" />
          <Typography style={{
          marginLeft: "20px",
          marginTop: "2px"
        }}>
            {MESSAGES.loading}.....
          </Typography>
        </Box>
      </Grid>;
  };

  // Function to render errorMessage content
  const errorMessageContent = () => {
    return <Grid style={{
      justifyContent: "center"
    }}>
        <Box style={{
        ...LOADING_OR_ERROR_CONTENT_STYLE
      }}>
          <Typography variant="h2" style={{
          marginLeft: "20px",
          marginTop: "2px",
          textTransform: "capitalize"
        }}>
            {errorMessage}.....
          </Typography>
        </Box>
      </Grid>;
  };

  // Render score sheet component on conditional based
  const renderScoreSheetComponent = () => {
    let scoreSheetComponent;
    if (teamId) {
      scoreSheetComponent = renderScoreSheetByTeam();
    } else if (enableRouting && eventDivisionTeam) {
      scoreSheetComponent = renderScoreSheetByTeamId();
    } else {
      scoreSheetComponent = renderScoreSheet();
    }
    return <div className="print-only">
        {scoreSheetComponent}
      </div>;
  };

  // Render content based on conditional
  const renderContent = () => {
    let content;
    if (isLoading) {
      content = renderLoader();
    } else if (errorMessage) {
      content = errorMessageContent();
    } else {
      content = renderScoreSheetComponent();
    }
    return content;
  };
  return <React.Fragment>
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            .print-only, .print-only * {
              visibility: visible !important;
              display: block !important;
            }
            .print-only {
              position: absolute;
              left: 0;
              top: 0;
              width: 100% !important;
              margin: 0 !important;
              padding: 0 !important;
            }
            * {
              color: black !important;
              border-color: black !important;
              font-size: 75% !important;
              box-sizing: border-box !important;
            }
            table {
              page-break-inside: avoid !important;
            }
            .highlighted-text {
              color: ${PRIMARY_COLOR} !important;
              font-weight: bold !important;
            }
            .rubric-heading {
              background: black !important;
              color: white !important;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .rubric-area-container {
              border: 2px solid;
              width: 100%;
              min-height: 160px;
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              overflow: hidden;
            }
            table {
              width: 100% !important;
              margin-bottom: 1rem !important;
              border-collapse: collapse !important;
            }
            
            .MuiTable-root {
              display: table !important;
            }
            
            .MuiTableHead-root {
              display: table-header-group !important;
            }
            
            .MuiTableBody-root {
              display: table-row-group !important;
            }
            
            .MuiTableRow-root {
              display: table-row !important;
              page-break-inside: avoid !important;
            }
            
            .MuiTableCell-root {
              display: table-cell !important;
              padding: 8px !important;
              border: 1px solid black !important;
            }
          }
        `}
      </style>
      <div className="score-sheet-container print-only">
        {!isLoading && !errorMessage && <>
            {renderFilters()}
            {renderEventFilter()}
          </>}
        {renderContent()}
      </div>
    </React.Fragment>;
};
export default ScoreSheet;