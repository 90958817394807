import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store";
import { selectBothSides, selectCurrentUser, selectUserPrograms, selectUserProducers, selectCurrentLoginState, selectTryLogin, selectSuperUser, selectPermissionCodes, selectUserJudgeEvents, selectCurrentUserId, selectCurrentUserEmail } from "store/user/userSelectors";
import { selectCurrentProgram, selectCurrentLocation, selectCurrentTeam, selectAthlete, selectCoach, selectPersonnel, selectEventRegistrationLogo } from "store/program/programSelectors";
import { selectCurrentProducer, selectCurrentEvent, selectCurrentBrand } from "store/producer/producerSelectors";
import { ProducerPermissions, ProgramPermissions, ProgramPermission, LocationPermission, ProducerPermission, BrandPermission, JudgeEventPermission, JudgeEventPermissions } from "store/user/userReducer";
import { BothSides } from "store/user/userActions";
import { doSetProgram, clearProgram } from "store/program/programActions";
import { clearLocation, doSetLocation } from "store/program/locationActions";
import { clearTeam, doSetTeam } from "store/program/teamActions";
import { doSetProducer, clearProducer } from "store/producer/producerActions";
import { doClearBrand, doSetBrand } from "store/producer/brandActions";
import { clearEvent, doSetEvent } from "store/producer/eventActions";
import Header from "./Header";
import { validateBrandPermission, validateLocationPermission, validateProducerPermission, validateProgramPermission } from "lib/permissions";
import * as Sentry from '@sentry/react';
import { crisp } from '../../index';
const HeaderContainer: React.FC = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const userStateStorage = localStorage.getItem('programOrProducer') || 'judge';
  const [userState, setUserState] = useState(userStateStorage);
  const [currentProducer, setCurrentProducer] = useState<ProducerPermission>();
  const [currentBrand, setCurrentBrand] = useState<BrandPermission>();
  const [currentEventId, setCurrentEventId] = useState<number>();
  const [currentProgram, setCurrentProgram] = useState<ProgramPermission>();
  const [currentLocation, setCurrentLocation] = useState<LocationPermission>();
  const [currentTeamId, setCurrentTeamId] = useState<number>();
  const [currentAthleteId, setCurrentAthleteId] = useState<number>();
  const [currentCoachId, setCurrentCoachId] = useState<number>();
  const [currentPersonnelId, setCurrentPersonnelId] = useState<number>();
  const [currentEventRegistrationLogo, setCurrentEventRegistrationLogo] = useState<string | undefined>();
  const [currentJudgeEvent, setCurrentJudgeEvent] = useState<JudgeEventPermission>();
  const username: string | null = useSelector((state: IAppState) => selectCurrentUser(state));
  const userId: number | null = useSelector((state: IAppState) => selectCurrentUserId(state));
  const userEmail: string | null = useSelector((state: IAppState) => selectCurrentUserEmail(state));
  const programs: ProgramPermissions = useSelector((state: IAppState) => selectUserPrograms(state));
  const producers: ProducerPermissions = useSelector((state: IAppState) => selectUserProducers(state));
  const judgeEvents: JudgeEventPermissions = useSelector((state: IAppState) => selectUserJudgeEvents(state));
  const bothSides: BothSides = useSelector((state: IAppState) => selectBothSides(state));
  const loggedIn: boolean = useSelector((state: IAppState) => selectCurrentLoginState(state));
  const tryLogin = useSelector((state: IAppState) => selectTryLogin(state));
  const stateProgram = useSelector((state: IAppState) => selectCurrentProgram(state));
  const stateLocation = useSelector((state: IAppState) => selectCurrentLocation(state));
  const stateTeam = useSelector((state: IAppState) => selectCurrentTeam(state));
  const stateAthlete = useSelector((state: IAppState) => selectAthlete(state, currentAthleteId || 0));
  const stateCoach = useSelector((state: IAppState) => selectCoach(state, currentCoachId || 0));
  const statePersonnel = useSelector((state: IAppState) => selectPersonnel(state, currentPersonnelId || 0));
  const isSuperUser: boolean = useSelector((state: IAppState) => selectSuperUser(state));
  const stateProducer = useSelector((state: IAppState) => selectCurrentProducer(state));
  const stateBrand = useSelector((state: IAppState) => selectCurrentBrand(state));
  const stateEvent = useSelector((state: IAppState) => selectCurrentEvent(state));
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state));
  const eventRegistrationLogo = useSelector((state: IAppState) => selectEventRegistrationLogo(state));
  useEffect(() => {
    const userStateStorage = localStorage.getItem('programOrProducer') || 'program';
    setUserState(userStateStorage);
  }, [stateProgram]);
  useEffect(() => {
    try {
      // Set Sentry User
      Sentry.setUser({
        email: userEmail ?? '',
        userID: userId?.toString() ?? '',
        username: username ?? ''
      });
      crisp.user.setEmail(userEmail ?? '');
      crisp.user.setNickname(username ?? '');
    } catch (error) {
      console.error('error setting Sentry User: ', error);
    }
  }, [username, userId, userEmail]);
  useEffect(() => {
    // If isProgramNotProducer changes, update local state
    localStorage.setItem('programOrProducer', userState);
  }, [userState]);
  useEffect(() => {
    //This section keeps the redux state consistent with where we are in the system
    if (!Object.keys(producers).length && !Object.keys(programs).length) return;
    const toParse = location.pathname.split('/');
    toParse.shift();
    const type = toParse[0]?.toLowerCase();
    const id = Number(toParse[1]);
    const subType = toParse[2]?.toLowerCase();
    const subId = Number(toParse[3]);
    const subSubType = toParse[4]?.toLowerCase();
    const subSubId = Number(toParse[5]);

    // Setting event registration logo
    if (type === "register" && subType === "team" || type === "registration" && subType === "team") {
      setCurrentEventRegistrationLogo(eventRegistrationLogo);
    } else {
      setCurrentEventRegistrationLogo(undefined);
    }
    switch (type) {
      case "judgeevent":
        setUserState('judge');
        setCurrentJudgeEvent(judgeEvents[0]);
        break;
      case "producer":
      case "editproducer":
      case "createmerchantaccount":
        setUserState('producer');
        setCurrentProducer(producers[id]);
        setCurrentBrand(undefined);
        setCurrentEventId(undefined);
        break;
      case "brand":
      case "editbrand":
        setUserState('producer');
        for (const producerId in producers) {
          if (producers[producerId].brands[id]) {
            setCurrentProducer(producers[producerId]);
            setCurrentBrand(producers[producerId].brands[id]);
          }
        }
        if (subType === "event" || subType === "editevent") {
          setCurrentEventId(subId);
        } else {
          setCurrentEventId(undefined);
        }
        break;
      case "program":
      case "editprogram":
        setUserState('program');
        setCurrentProgram(programs[id]);
        setCurrentLocation(undefined);
        setCurrentTeamId(undefined);
        break;
      case "register":
      case "registration":
      case "location":
      case "editlocation":
        setUserState('program');
        for (const programId in programs) {
          if (programs[programId].locations[id]) {
            setCurrentProgram(programs[programId]);
            setCurrentLocation(programs[programId].locations[id]);
          }
        }
        if (subType === "team" || subType === "editteam") {
          setCurrentTeamId(subId);
        } else {
          setCurrentTeamId(undefined);
        }
        if (subSubType === "athlete" || subSubType === "editathlete") {
          setCurrentAthleteId(subSubId);
        } else if (subType === "athlete" || subType === "editathlete") {
          setCurrentAthleteId(subId);
        } else {
          setCurrentAthleteId(undefined);
        }
        if (subSubType === "coach" || subSubType === "editcoach") {
          setCurrentCoachId(subSubId);
        } else if (subType === "coach" || subType === "editcoach") {
          setCurrentCoachId(subId);
        } else {
          setCurrentCoachId(undefined);
        }
        if (subSubType === "personnel" || subSubType === "editpersonnel") {
          setCurrentPersonnelId(subSubId);
        } else if (subType === "personnel" || subType === "editpersonnel") {
          setCurrentPersonnelId(subId);
        } else {
          setCurrentPersonnelId(undefined);
        }
        break;
      default:
        console.log('hit default: ', type);
        setCurrentProducer(undefined);
        setCurrentBrand(undefined);
        setCurrentEventId(undefined);
        setCurrentProgram(undefined);
        setCurrentLocation(undefined);
        setCurrentTeamId(undefined);
        setCurrentEventRegistrationLogo(undefined);
    }
  }, [location, producers, programs, eventRegistrationLogo]);
  useEffect(() => {
    // Load the redux state as needed from our current local state settings for program/location/team
    if (userState !== 'program') return;
    if (!currentProgram?.id) clearProgram(dispatch);else if (currentProgram.id && currentProgram.id !== stateProgram?.id) doSetProgram(dispatch, currentProgram.id);else if (currentLocation?.id && currentLocation?.id !== stateLocation?.id) doSetLocation(dispatch, currentProgram.id, currentLocation.id);else if (!currentLocation?.id) clearLocation(dispatch);else if (currentTeamId && currentTeamId !== stateTeam?.id && currentLocation.id) doSetTeam(dispatch, currentProgram.id, currentLocation.id, currentTeamId || 0);else if (!currentTeamId) clearTeam(dispatch);
  }, [currentProgram?.id, stateProgram?.id, currentLocation?.id, stateLocation?.id, currentTeamId, stateTeam?.id, dispatch, userState]);
  useEffect(() => {
    // Load the redux state as needed from our current local state settings for producer/brand/event
    if (userState !== 'producer') return;
    if (!currentProducer) {
      clearProducer(dispatch);
    } else if (currentProducer && stateProducer && currentProducer.id !== stateProducer.id) {
      doSetProducer(dispatch, currentProducer.id);
    } else if (stateBrand && currentBrand && currentBrand.id !== stateBrand?.id) {
      doSetBrand(dispatch, currentBrand.id, currentProducer.id);
    } else if (!currentBrand) doClearBrand(dispatch);
    if (stateEvent && currentEventId && currentEventId !== stateEvent?.id) {
      doSetEvent(dispatch, currentEventId);
    } else if (!currentEventId) clearEvent(dispatch);
  }, [currentProducer?.id, stateProducer?.id, currentBrand?.id, stateBrand?.id, currentEventId, stateEvent?.id, dispatch, currentProducer, stateProducer, stateBrand, currentBrand, stateEvent, userState]);
  useEffect(() => {
    // This makes sure that the team we have loaded is part of the location we have access to
    if (!stateLocation || !stateTeam || !currentLocation || stateLocation.id !== currentLocation.id || stateTeam.id !== currentTeamId) return;
    if (!stateLocation.teamsList.find(team => team.id === stateTeam.id)) {
      history.push('/');
    }
  }, [stateLocation, stateTeam, currentLocation, currentTeamId, history]);
  useEffect(() => {
    // This makes sure that the event we have loaded is part of the brand we have access to
    if (!stateBrand || !stateBrand.eventsList || !stateEvent || !currentBrand || stateBrand.id !== currentBrand.id || stateEvent.id !== currentEventId) return;
    if (!stateBrand.eventsList.find(event => event.id === stateEvent.id)) {
      history.push('/');
    }
  }, [stateBrand, stateEvent, currentBrand, currentEventId, history]);
  useEffect(() => {
    // Sets and removes localStorage ids for splash to grab and redirect user to last page they were on
    if (stateLocation?.id) {
      localStorage.setItem('latestLocationId', stateLocation.id.toString());
      localStorage.removeItem('latestProgramId');
    } else if (stateProgram?.id) {
      localStorage.setItem('latestProgramId', stateProgram.id.toString());
      localStorage.removeItem('latestLocationId');
    }
    if (stateBrand?.id) {
      localStorage.setItem('latestBrandId', stateBrand.id.toString());
      localStorage.removeItem('latestProducerId');
    } else if (stateProducer?.id) {
      localStorage.setItem('latestProducerId', stateProducer.id.toString());
      localStorage.removeItem('latestBrandId');
    }
  }, [stateProgram, stateLocation, stateProducer, stateBrand]);
  useEffect(() => {
    if (location.pathname.includes('/JudgeEvent')) {
      // Add the style tag for header modifications
      const styleTag = document.createElement('style');
      styleTag.innerHTML = `
        /* Header size adjustments for judge event */
        header {
          min-height: 80px !important;
        }
        header img.headerLogo {
          height: 40px !important;
        }
        header .MuiTypography-h1 {
          font-size: 1.25rem !important;
        }
        header .MuiTypography-body1 {
          font-size: 0.75rem !important;
        }
        header .MuiButton-root {
          font-size: 0.75rem !important;
          padding: 4px 8px !important;
        }
        header .MuiOutlinedInput-root {
          font-size: 0.75rem !important;
        }
        header .navigationMenu {
          margin-top: 4px !important;
        }
        header .MuiAutocomplete-input {
          padding: 4px 8px !important;
        }
      `;
      document.head.appendChild(styleTag);

      // Cleanup function
      return () => {
        styleTag.remove();
      };
    }
  }, [location]);
  const validatedPrograms: ProgramPermissions = useMemo(() => {
    const outPermissions: ProgramPermissions = {};
    for (const programKey in programs) {
      outPermissions[programKey] = programs[programKey];
      if (!validateProgramPermission(permissionCodes.programView, permissionCodes, isSuperUser, programs, Number(programKey))) {
        outPermissions[programKey].name = ""; // This removes it from both the dropdown selector and the breadcrumbs
      }
      if (validateProgramPermission(permissionCodes.programView, permissionCodes, isSuperUser, programs, Number(programKey))) {
        for (const locationKey in programs[programKey].locations) {
          if (!validateLocationPermission(permissionCodes.locationView, permissionCodes, isSuperUser, programs, Number(programKey), Number(locationKey))) {
            delete outPermissions[programKey].locations[locationKey];
          }
        }
      }
    }
    return outPermissions;
  }, [isSuperUser, programs, permissionCodes]);
  const validatedProducers: ProducerPermissions = useMemo(() => {
    const outPermissions: ProducerPermissions = {};
    for (const producerKey in producers) {
      outPermissions[producerKey] = producers[producerKey];
      if (!validateProducerPermission(permissionCodes.producerView, permissionCodes, isSuperUser, producers, Number(producerKey))) {
        outPermissions[producerKey].name = ""; // This removes it from both the dropdown selector and the breadcrumbs
      }
      if (validateProducerPermission(permissionCodes.producerView, permissionCodes, isSuperUser, producers, Number(producerKey))) {
        for (const brandKey in producers[producerKey].brands) {
          if (!validateBrandPermission(permissionCodes.brandView, permissionCodes, isSuperUser, producers, Number(producerKey), Number(brandKey))) {
            delete outPermissions[producerKey].brands[brandKey];
          }
        }
      }
    }
    return outPermissions;
  }, [isSuperUser, producers, permissionCodes]);
  return permissionCodes?.denyAccess.length && validatedProducers && validatedPrograms ? <Header tryLogin={tryLogin} loggedIn={loggedIn} username={username || ""} isSuperUser={isSuperUser} bothSides={bothSides} producers={validatedProducers} programs={validatedPrograms} judgeEvents={judgeEvents} userState={userState} setUserState={setUserState} currentProgram={currentProgram} setCurrentProgram={setCurrentProgram} currentProducer={currentProducer} setCurrentProducer={setCurrentProducer} currentJudgeEvent={currentJudgeEvent} setCurrentJudgeEvent={setCurrentJudgeEvent} stateAthlete={stateAthlete} stateCoach={stateCoach} stateEvent={stateEvent} statePersonnel={statePersonnel} stateTeam={stateTeam} currentBrand={currentBrand} currentLocation={currentLocation} currentEventRegistrationLogo={currentEventRegistrationLogo} /> : <></>;
};
export default HeaderContainer;